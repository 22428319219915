
.catCard:hover {
    transform: scale(0.96);
}

.svg {
    display: block;
    width: 100%;
    height: 200px;
    background: #329bb3;
}
